import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import React, { FC } from 'react'
import { ReactComponent as SceneIcon } from '../../Assets/icons/3DScene.svg'
import { ReactComponent as AccentureLoginIcon } from '../../Assets/icons/AccentureLogIn.svg'
import { ReactComponent as AchievementReceivedIcon } from '../../Assets/icons/AchievementReceived.svg'
import { ReactComponent as AddPersonIcon } from '../../Assets/icons/AddPerson.svg'
import { ReactComponent as ReportUserIcon } from '../../Assets/icons/ReportUser.svg'
import { ReactComponent as BackIcon } from '../../Assets/icons/Back.svg'
import { ReactComponent as BusinessCardsIcon } from '../../Assets/icons/BusinessCards.svg'
import { ReactComponent as CameraIcon } from '../../Assets/icons/Camera.svg'
import { ReactComponent as ChangeAvatarIcon } from '../../Assets/icons/ChangeAvatar.svg'
import { ReactComponent as ChatIcon } from '../../Assets/icons/Chat.svg'
import { ReactComponent as CloseIcon } from '../../Assets/icons/Close.svg'
import { ReactComponent as CoinIcon } from '../../Assets/icons/Coin.svg'
import { ReactComponent as ConfettiIcon } from '../../Assets/icons/Confetti.svg'
import { ReactComponent as CornerIcon } from '../../Assets/icons/Corner.svg'
import { ReactComponent as DeleteAccountIcon } from '../../Assets/icons/DeleteAccount.svg'
import { ReactComponent as DoNotDisturbIcon } from '../../Assets/icons/DoNotDisturb.svg'
import { ReactComponent as ErrorCrossIcon } from '../../Assets/icons/ErrorCross.svg'
import { ReactComponent as EventNotificationIcon } from '../../Assets/icons/EventNotification.svg'
import { ReactComponent as FacebookLogInIcon } from '../../Assets/icons/FacebookLogIn.svg'
import { ReactComponent as FlagIcon } from '../../Assets/icons/Flag.svg'
import { ReactComponent as GroupIcon } from '../../Assets/icons/Group.svg'
import { ReactComponent as HelpIcon } from '../../Assets/icons/Help.svg'
import { ReactComponent as InfoIcon } from '../../Assets/icons/Info.svg'
import { ReactComponent as InterestsIcon } from '../../Assets/icons/Interests.svg'
import { ReactComponent as KTBIcon } from '../../Assets/icons/KTB.svg'
import { ReactComponent as GoogleLogInIcon } from '../../Assets/icons/Google.svg'
import { ReactComponent as LinkedInLoginIcon } from '../../Assets/icons/LinkedInLogIn.svg'
import { ReactComponent as LogOutIcon } from '../../Assets/icons/LogOut.svg'
import { ReactComponent as LoudSpeakerIcon } from '../../Assets/icons/LoudSpeaker.svg'
import { ReactComponent as MainMenuIcon } from '../../Assets/icons/MainMenu.svg'
import { ReactComponent as MainMenuHoverIcon } from '../../Assets/icons/MainMenuHover.svg'
import { ReactComponent as MenuIcon } from '../../Assets/icons/Menu.svg'
import { ReactComponent as MicrophoneIcon } from '../../Assets/icons/Microphone.svg'
import { ReactComponent as MicrophoneOffIcon } from '../../Assets/icons/MicrophoneOff.svg'
import { ReactComponent as MiniCoinIcon } from '../../Assets/icons/MiniCoin.svg'
import { ReactComponent as MiniCoinGoldIcon } from '../../Assets/icons/MiniCoinGold.svg'
import { ReactComponent as MiniMapOffIcon } from '../../Assets/icons/MiniMapOffButton.svg'
import { ReactComponent as MiniMapOnIcon } from '../../Assets/icons/MiniMapOn.svg'
import { ReactComponent as MiniMapOnHoverIcon } from '../../Assets/icons/MiniMapOnHover.svg'
import { ReactComponent as MiniMapOffHoverIcon } from '../../Assets/icons/MiniMapOffButtonHover.svg'
import { ReactComponent as MusicNoteIcon } from '../../Assets/icons/MusicNote.svg'
import { ReactComponent as MusicNoteOffIcon } from '../../Assets/icons/MusicNoteOff.svg'
import { ReactComponent as MuteMicrophoneIcon } from '../../Assets/icons/MuteMicrophone.svg'
import { ReactComponent as MuteUserMicrophoneIcon } from '../../Assets/icons/MuteUserMicrophone.svg'
import { ReactComponent as UnmuteUserMicrophoneIcon } from '../../Assets/icons/UnmuteUserMicrophone.svg'
import { ReactComponent as MuteVideoIcon } from '../../Assets/icons/MuteVideo.svg'
import { ReactComponent as MuteVideoHoverIcon } from '../../Assets/icons/MuteVideoHover.svg'
import { ReactComponent as NewsFeedNotification } from '../../Assets/icons/NewsFeedNotification.svg'
import { ReactComponent as NotificationAlertIcon } from '../../Assets/icons/NotificationAlert.svg'
import { ReactComponent as NotificationBellIcon } from '../../Assets/icons/NotificationBell.svg'
import { ReactComponent as NotificationBellOffIcon } from '../../Assets/icons/NotificationBellOff.svg'
import { ReactComponent as PassportIcon } from '../../Assets/icons/Passport.svg'
import { ReactComponent as PasswordChangeIcon } from '../../Assets/icons/PasswordChange.svg'
import { ReactComponent as ExportChatIcon } from '../../Assets/icons/ExportPrivateChat.svg'
import { ReactComponent as PhoneIcon } from '../../Assets/icons/Phone.svg'
import { ReactComponent as PhotoCameraIcon } from '../../Assets/icons/PhotoCamera.svg'
import { ReactComponent as PictureChangeIcon } from '../../Assets/icons/PictureChange.svg'
import { ReactComponent as PmiLogInIcon } from '../../Assets/icons/PmiLogo.svg'
import { ReactComponent as ProfileInfoIcon } from '../../Assets/icons/ProfileInfo.svg'
import { ReactComponent as RecruitingDashboardIcon } from '../../Assets/icons/RecruitingDashboard.svg'
import { ReactComponent as RequestBusinessCardIcon } from '../../Assets/icons/RequestBusinessCard.svg'
import { ReactComponent as RocheLogInIcon } from '../../Assets/icons/RocheLogIn.svg'
import { ReactComponent as RoomChatIcon } from '../../Assets/icons/RoomChat.svg'
import { ReactComponent as SearchIcon } from '../../Assets/icons/Search.svg'
import { ReactComponent as SendIcon } from '../../Assets/icons/Send.svg'
import { ReactComponent as SettingsIcon } from '../../Assets/icons/Settings.svg'
import { ReactComponent as SkillsIcon } from '../../Assets/icons/Skills.svg'
import { ReactComponent as StatusChangeIcon } from '../../Assets/icons/StatusChange.svg'
import { ReactComponent as SuccessCheckIcon } from '../../Assets/icons/SuccessCheck.svg'
import { ReactComponent as SwitchTPP } from '../../Assets/icons/SwitchTPP.svg'
import { ReactComponent as SwitchFPP } from '../../Assets/icons/SwitchFPP.svg'
import { ReactComponent as TokenWalletIcon } from '../../Assets/icons/TokenWallet.svg'
import { ReactComponent as TrashIcon } from '../../Assets/icons/Trash.svg'
import { ReactComponent as UploadIcon } from '../../Assets/icons/Upload.svg'
import { ReactComponent as UserListIcon } from '../../Assets/icons/UserList.svg'
import { ReactComponent as VideoIcon } from '../../Assets/icons/Video.svg'
import { ReactComponent as VideoCallIcon } from '../../Assets/icons/VideoCall.svg'
import { ReactComponent as VideoHoverIcon } from '../../Assets/icons/VideoHover.svg'
import { ReactComponent as VisibilityOffIcon } from '../../Assets/icons/VisibilityOff.svg'
import { ReactComponent as VisibilePasswordIcon } from '../../Assets/icons/VisiblePassword.svg'
import { ReactComponent as VoiceCallIcon } from '../../Assets/icons/VoiceCall.svg'
import { ReactComponent as WarningIcon } from '../../Assets/icons/Warning.svg'
import { ReactComponent as XIcon } from '../../Assets/icons/X.svg'
import { ReactComponent as BurgerMenuIcon } from '../../Assets/icons/BurgerMenu.svg'
import MicrophoneHoverIcon from '../../Assets/icons/MicrophoneHover'
import MuteMicrophoneHoverIcon from '../../Assets/icons/MuteMicrophoneHover'

export interface IconProps {
  iconName:
    | 'addBusinessCard'
    | 'addPerson'
    | 'reportUser'
    | 'eventNotification'
    | 'back'
    | 'backgroundMusic'
    | 'backgroundMusicOff'
    | 'businessCards'
    | 'changeLanguage'
    | 'chat'
    | 'checked'
    | 'close'
    | 'group'
    | 'help'
    | 'logOut'
    | 'menu'
    | 'microphone'
    | 'microphoneHover'
    | 'microphoneOff'
    | 'newsFeedNotification'
    | 'passport'
    | 'search'
    | 'send'
    | 'settings'
    | 'scene'
    | 'videoCall'
    | 'visiblePassword'
    | 'voiceCall'
    | 'camera'
    | 'phone'
    | 'requestBusinessCard'
    | 'accentureLogin'
    | 'linkedInLogin'
    | 'pmiLogo'
    | 'visiblePassword'
    | 'visibilityOff'
    | 'confetti'
    | 'notificationBell'
    | 'notificationBellOff'
    | 'musicNote'
    | 'musicNoteOff'
    | 'flag'
    | 'successCheck'
    | 'errorCross'
    | 'roomChat'
    | 'info'
    | 'warning'
    | 'businessCards'
    | 'doNotDisturb'
    | 'miniMapToggle'
    | 'miniMapOn'
    | 'miniMapOff'
    | 'miniMapOnHover'
    | 'miniMapOffHover'
    | 'upload'
    | 'trash'
    | 'photoCamera'
    | 'statusChange'
    | 'pictureChange'
    | 'changeAvatar'
    | 'profileInfo'
    | 'skills'
    | 'interests'
    | 'passwordChange'
    | 'ExportPrivateChat'
    | 'deleteAccount'
    | 'x'
    | 'mainMenu'
    | 'mainMenuHover'
    | 'corner'
    | 'muteVideo'
    | 'muteVideoHover'
    | 'video'
    | 'videoHover'
    | 'muteMicrophone'
    | 'muteUserMicrophone'
    | 'unmuteUserMicrophone'
    | 'muteMicrophoneHover'
    | 'loudSpeaker'
    | 'coin'
    | 'miniCoin'
    | 'miniCoinGold'
    | 'userList'
    | 'rocheLogIn'
    | 'notificationAlert'
    | 'facebookLogIn'
    | 'switchTPP'
    | 'switchFPP'
    | 'tokenWallet'
    | 'recruitingDashboard'
    | 'achievementReceived'
    | 'ktb'
    | 'googleLogIn'
    | 'burgerMenu'

  onClick?: null | (() => void)
  iconSize?: string
  svgIconProps?: any
  iconButtonProps?: any
  disableBackground?: boolean
  title?: string
  id?: string
}

export const Icon: FC<IconProps> = ({
  onClick,
  iconName,
  iconSize = '20px',
  svgIconProps,
  iconButtonProps,
  disableBackground,
  title,
  id,
}) => {
  const renderIcon = (id?: string) => {
    switch (iconName) {
      case 'back': {
        return <BackIcon />
      }
      case 'chat': {
        return <ChatIcon />
      }
      case 'close': {
        return <CloseIcon />
      }
      case 'addPerson': {
        return <AddPersonIcon />
      }
      case 'reportUser': {
        return <ReportUserIcon />
      }
      case 'group': {
        return <GroupIcon />
      }
      case 'roomChat': {
        return <RoomChatIcon />
      }
      case 'help': {
        return <HelpIcon />
      }
      case 'logOut': {
        return <LogOutIcon />
      }
      case 'menu': {
        return <MenuIcon />
      }
      case 'microphone': {
        return <MicrophoneIcon />
      }
      case 'microphoneHover': {
        return <MicrophoneHoverIcon id={id} />
      }
      case 'microphoneOff': {
        return <MicrophoneOffIcon />
      }
      case 'newsFeedNotification': {
        return <NewsFeedNotification />
      }
      case 'passport': {
        return <PassportIcon />
      }
      case 'search': {
        return <SearchIcon />
      }
      case 'send': {
        return <SendIcon />
      }
      case 'settings': {
        return <SettingsIcon />
      }
      case 'scene': {
        return <SceneIcon />
      }
      case 'videoCall': {
        return <VideoCallIcon />
      }
      case 'linkedInLogin': {
        return <LinkedInLoginIcon />
      }
      case 'accentureLogin': {
        return <AccentureLoginIcon />
      }
      case 'pmiLogo': {
        return <PmiLogInIcon />
      }
      case 'visiblePassword': {
        return <VisibilePasswordIcon />
      }
      case 'visibilityOff': {
        return <VisibilityOffIcon />
      }
      case 'voiceCall': {
        return <VoiceCallIcon />
      }
      case 'camera': {
        return <CameraIcon />
      }
      case 'phone': {
        return <PhoneIcon />
      }
      case 'requestBusinessCard': {
        return <RequestBusinessCardIcon />
      }
      case 'eventNotification': {
        return <EventNotificationIcon />
      }
      case 'confetti': {
        return <ConfettiIcon />
      }
      case 'notificationBell': {
        return <NotificationBellIcon />
      }
      case 'notificationBellOff': {
        return <NotificationBellOffIcon />
      }
      case 'musicNote': {
        return <MusicNoteIcon />
      }
      case 'musicNoteOff': {
        return <MusicNoteOffIcon />
      }
      case 'flag': {
        return <FlagIcon />
      }
      case 'successCheck': {
        return <SuccessCheckIcon />
      }
      case 'errorCross': {
        return <ErrorCrossIcon />
      }
      case 'info': {
        return <InfoIcon />
      }
      case 'warning': {
        return <WarningIcon />
      }
      case 'businessCards': {
        return <BusinessCardsIcon />
      }
      case 'doNotDisturb': {
        return <DoNotDisturbIcon />
      }
      case 'miniMapOn': {
        return <MiniMapOnIcon />
      }
      case 'miniMapOff': {
        return <MiniMapOffIcon />
      }
      case 'miniMapOffHover': {
        return <MiniMapOffHoverIcon />
      }
      case 'miniMapOnHover': {
        return <MiniMapOnHoverIcon />
      }
      case 'upload': {
        return <UploadIcon />
      }
      case 'trash': {
        return <TrashIcon />
      }
      case 'photoCamera': {
        return <PhotoCameraIcon />
      }
      case 'statusChange': {
        return <StatusChangeIcon />
      }
      case 'pictureChange': {
        return <PictureChangeIcon />
      }
      case 'skills': {
        return <SkillsIcon />
      }
      case 'interests': {
        return <InterestsIcon />
      }
      case 'passwordChange': {
        return <PasswordChangeIcon />
      }
      case 'ExportPrivateChat': {
        return <ExportChatIcon />
      }
      case 'deleteAccount': {
        return <DeleteAccountIcon />
      }
      case 'changeAvatar': {
        return <ChangeAvatarIcon />
      }
      case 'profileInfo': {
        return <ProfileInfoIcon />
      }
      case 'x': {
        return <XIcon />
      }
      case 'mainMenu': {
        return <MainMenuIcon />
      }
      case 'mainMenuHover': {
        return <MainMenuHoverIcon />
      }
      case 'corner': {
        return <CornerIcon />
      }
      case 'video': {
        return <VideoIcon />
      }
      case 'videoHover': {
        return <VideoHoverIcon />
      }
      case 'muteVideo': {
        return <MuteVideoIcon />
      }
      case 'muteVideoHover': {
        return <MuteVideoHoverIcon />
      }
      case 'muteMicrophone': {
        return <MuteMicrophoneIcon />
      }
      case 'muteUserMicrophone': {
        return <MuteUserMicrophoneIcon />
      }
      case 'unmuteUserMicrophone': {
        return <UnmuteUserMicrophoneIcon />
      }
      case 'muteMicrophoneHover': {
        return <MuteMicrophoneHoverIcon id={id} />
      }
      case 'loudSpeaker': {
        return <LoudSpeakerIcon />
      }
      case 'coin': {
        return <CoinIcon />
      }
      case 'miniCoin': {
        return <MiniCoinIcon />
      }
      case 'miniCoinGold': {
        return <MiniCoinGoldIcon />
      }
      case 'userList': {
        return <UserListIcon />
      }
      case 'rocheLogIn': {
        return <RocheLogInIcon />
      }
      case 'notificationAlert': {
        return <NotificationAlertIcon />
      }
      case 'facebookLogIn': {
        return <FacebookLogInIcon />
      }
      case 'switchTPP': {
        return <SwitchTPP />
      }
      case 'switchFPP': {
        return <SwitchFPP />
      }
      case 'tokenWallet': {
        return <TokenWalletIcon />
      }
      case 'recruitingDashboard': {
        return <RecruitingDashboardIcon />
      }
      case 'achievementReceived': {
        return <AchievementReceivedIcon />
      }
      case 'ktb': {
        return <KTBIcon />
      }
      case 'googleLogIn': {
        return <GoogleLogInIcon />
      }
      case 'burgerMenu': {
        return <BurgerMenuIcon />
      }
      default: {
        return null
      }
    }
  }

  return onClick ? (
    <IconButton
      {...iconButtonProps}
      id={id}
      title={title}
      onClick={onClick}
      style={{ background: disableBackground ? 'transparent' : '' }}
    >
      <SvgIcon
        {...svgIconProps}
        style={{
          width: '1.1em',
          fontSize: `${iconSize}`,
        }}
      >
        {renderIcon()}
      </SvgIcon>
    </IconButton>
  ) : (
    <SvgIcon
      {...svgIconProps}
      onClick={onClick}
      style={{
        width: '1.1em',
        fontSize: `${iconSize}`,
      }}
    >
      {renderIcon(id)}
    </SvgIcon>
  )
}
