import React from 'react'
import { Helmet } from 'react-helmet'
import { projectName, getIndexPageData } from '../../Utils/utils'

export function IndexPageUpdater() {
  const descriptionValue = `Accenture's ${projectName}`
  const indexPageData = getIndexPageData()
  return (
    <Helmet>
      <title>{projectName}</title>
      <meta name='description' content={descriptionValue} />
      <meta property='og:title' content={projectName} />
      <meta property='og:og:description' content={descriptionValue} />
      <link rel='icon' href={indexPageData.iconPath} />
      <link rel='shortcut icon' href={indexPageData.iconPath} />
    </Helmet>
  )
}
