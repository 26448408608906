import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Socket } from 'socket.io-client'
import {
  Dropdown,
  DropdownItem,
  MenuDrawer,
  MenuHeader,
  ShadowedButton,
} from '../../Common/common-index'
import DropdownFiltered from '../../Common/Components/Dropdown/DropdownFiltered'
import { EventNotification } from '../../Components/EventNotification/EventNotification'
import { NotificationSubmitMessage } from '../../Components/EventNotification/NotificationSubmitMessage'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { contentService, messagingService, User, userService } from '../../Services/services-index'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { updateHasNotificationPageOpen } from '../RecruitingDashboard/RecruitingDashboardSlice'
import {
  updateAudienceOptions,
  updateMessageDismissDurationTypes,
  updateNotificationTypes,
} from './EventNotificationSlice'
import { IndexPageUpdater } from '../../Components/IndexPageUpdater/IndexPageUpdater'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  pageContainer: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  itemWrapper: {
    marginTop: theme.spacing(5),
  },
  submitButton: {
    marginTop: theme.spacing(5),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
  notificationSubmitMessage: {
    padding: '0',
    height: '100%',
  },
}))

export interface Props {
  isRecruitingDashboardNotification?: boolean
}

export const EventNotificationContainer: FC<Props> = ({ isRecruitingDashboardNotification }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const socket: Socket | null = useAppSelector((state) => state.socket.socket)

  const selectedRecruitingUserId = useAppSelector(
    (state) => state.recruiting.selectedRecruitingUserId
  )

  const defaultNotificationTypes = [
    { value: 1, name: 'Information' },
    { value: 2, name: 'Success' },
    { value: 3, name: 'Warning' },
    { value: 4, name: 'Error' },
  ]

  const defaultMessageDurationTypes = [
    { value: 1, name: '3s' },
    { value: 2, name: '5s' },
    { value: 3, name: '10s' },
    { value: 4, name: '20s' },
    { value: 5, name: 'Until closed manually' },
  ]

  const availableAudienceOptions = useAppSelector(
    (state) => state.eventNotification.audienceOptions
  )

  const availableNotificationTypes = useAppSelector(
    (state) => state.eventNotification.notificationTypes
  )

  const availableMessageDismissDurations = useAppSelector(
    (state) => state.eventNotification.messageDismissDurationTypes
  )

  // Initialization of State value
  const [eventCode, setEventCode] = useState<string>('')
  const [notificationTitle, setNotificationTitleValue] = useState<string>('')
  const [textAreaInputValue, setTextAreaInputValue] = useState<string>('')
  const [selectedAudienceOption, setSelectedAudienceOptionValue] = useState<any>()
  const [availableRecipientOptions, setAvailableRecipientOption] = useState<DropdownItem[]>([])
  const [selectedRecipientOption, setSelectedRecipientOption] = useState<any>()
  const [selectedRecipientValue, setSelectedRecipientValue] = useState<any>()
  const [selectedNotificationType, setSelectedNotificationTypeValue] = useState<any>({
    value: 1,
    name: 'Information',
  })
  const [selectedMessageDismissDuration, setSelectedMessageDismissDuration] = useState<any>({
    value: 2,
    name: '5s',
  })
  const [textAreaInputCharacterCount, setTextAreaInputCharacterCount] = useState<number>(0)

  const [isMenuOpen, setMenuOpen] = useState(true)
  const [isNotificationPublished, setIsNotificationPublished] = useState(false)
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false)
  const [isSubmissionFailed, setIsSubmissionFailed] = useState(false)
  const [eventNotificationPageOpen, setEventNotificationPageOpen] = useState(true)
  const [availableUsers, setAvailableUsers] = useState<User[]>([])

  useEffect(() => {
    if (!availableUsers.length && currentUser) {
      userService
        .getLoggedUsersInEvent(currentUser.event_code)
        .then((data: any) => {
          if (data?.status === 200) {
            const activeUserList = data?.data?.users
            const displayUserList = activeUserList.filter((u: User) => u.id !== currentUser?.id)
            setAvailableUsers(displayUserList)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    if (!eventCode) {
      if (currentUser) {
        const eventCode = currentUser.event_code
        setEventCode(eventCode)
      }
    }
    if (Object.keys(availableAudienceOptions).length === 0) {
      getAudienceOptions()
    }
    if (Object.keys(availableNotificationTypes).length === 0) {
      dispatch(updateNotificationTypes(defaultNotificationTypes))
    }
    if (Object.keys(availableMessageDismissDurations).length === 0) {
      dispatch(updateMessageDismissDurationTypes(defaultMessageDurationTypes))
    }
    const userListFiltered: any = []
    Object.entries(availableUsers).forEach((user) => {
      const recipient = { value: user[1].id, name: user[1].firstname + ' ' + user[1].lastname }
      userListFiltered.push(recipient)
    })
    setAvailableRecipientOption(userListFiltered)
  }, [
    eventCode,
    availableNotificationTypes,
    availableAudienceOptions,
    selectedAudienceOption,
    selectedRecipientOption,
    selectedNotificationType,
    isNotificationPublished,
    selectedMessageDismissDuration,
    availableUsers,
  ])

  // On notification title value change
  const onTitleValueChange = (event?: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setNotificationTitleValue(event.target.value)
    }
  }

  // On notification input text value change
  const onTextAreaValueChange = (event?: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setTextAreaInputValue(event.target.value)
      setTextAreaInputCharacterCount(event.target.value.length)
    }
  }

  // On audience option selection
  const onAudienceOptionValueChange = (event: ChangeEvent<any>) => {
    if (event && event.target) {
      setSelectedAudienceOptionValue({ value: event.target.value })
      setSelectedRecipientOption(undefined)
      setSelectedRecipientValue(undefined)
    }
  }

  //On recipient option selection
  const onRecipientOptionValueChange = (event: ChangeEvent<any>, value: any) => {
    if (event && event.target) {
      setSelectedRecipientValue(value)
      const recipientIds: any = []
      value.forEach((user: any) => recipientIds.push(user.value))
      setSelectedAudienceOptionValue(undefined)
      setSelectedRecipientOption(recipientIds || [])
    }
  }

  // On notification type selection
  const onNotificationTypeValueChange = (event: ChangeEvent<any>) => {
    if (event && event.target) {
      const selectedType = event.target.value
      const notification = availableNotificationTypes.find((t) => t?.value === selectedType)
      setSelectedNotificationTypeValue({
        value: selectedType,
        name: notification?.name,
      })
    }
  }
  const onMessageDismissDurationValueChange = (event: ChangeEvent<any>) => {
    if (event && event.target) {
      const selectedType = event.target.value
      const selectedDuration = availableMessageDismissDurations.find(
        (t) => t?.value === selectedType
      )
      setSelectedMessageDismissDuration({ value: selectedType, name: selectedDuration?.name })
    }
  }

  // On get audience options
  const getAudienceOptions = () => {
    const defaultAudienceOption = Object({ value: 999999999999999, name: 'All Rooms' })
    const audienceOptions: DropdownItem[] = []

    audienceOptions.push(defaultAudienceOption)
    contentService.getRooms().then((data: any) => {
      if (data) {
        const eventRooms = data.data.event_rooms.filter((event: any) => {
          return event.event === eventCode
        })[0]
        if (eventRooms) {
          eventRooms.rooms.forEach((room: any) => {
            audienceOptions.push({
              value: room.id,
              name: room.name,
            })
          })
          dispatch(updateAudienceOptions(audienceOptions))
        }
      }
    })
  }

  // Form fields validation
  const isEventNotificationFormEmpty = () => {
    if (notificationTitle === '' || textAreaInputValue === '') {
      return true
    } else if (
      !isRecruitingDashboardNotification
        ? selectedAudienceOption?.value === undefined &&
          (selectedRecipientOption === undefined || selectedRecipientOption.length === 0)
        : selectedRecruitingUserId === undefined ||
          selectedNotificationType?.value === undefined ||
          selectedMessageDismissDuration?.value === undefined
    ) {
      return true
    } else {
      return false
    }
  }

  // On notification submit button click
  const onNotificationSubmit = (e: any) => {
    e.preventDefault()
    setIsNotificationPublished(true)
    const notificationTypeValue = selectedNotificationType?.name?.toLowerCase()
    const messageTypeDurationValue = getDismissDuration(
      selectedMessageDismissDuration?.name?.toLowerCase()
    )

    let messageFormatted
    if (
      /\[[ ]*(([a-zA-Z0-9_]+[ ]*)+)[ ]*\|[ ]*([a-zA-Z0-9_./-]+)[ ]*\]/g.test(textAreaInputValue)
    ) {
      messageFormatted = textAreaInputValue.replace(
        /\[[ ]*(([a-zA-Z0-9_]+[ ]*)+)[ ]*\|[ ]*([a-zA-Z0-9_.:/-]+)[ ]*\]/g,
        '<a href="https://$3" target="_blank">$1</a>'
      )
    } else if (
      /\[[ ]*(([a-zA-Z0-9_]+[ ]*)+)[ ]*\|[ ]*(http:\/\/|https:\/\/)?([a-zA-Z0-9_.:/-]+)[ ]*\]/g.test(
        textAreaInputValue
      )
    ) {
      messageFormatted = textAreaInputValue.replace(
        /\[[ ]*(([a-zA-Z0-9_]+[ ]*)+)[ ]*\|[ ]*(http:\/\/|https:\/\/)?([a-zA-Z0-9_.:/-]+)[ ]*\]/g,
        '<a href="$3$4" target="_blank">$1</a>'
      )
    } else {
      messageFormatted = textAreaInputValue
    }

    let messagePayload: any
    messagePayload = {
      title: notificationTitle,
      message: messageFormatted,
      room_id:
        selectedAudienceOption && selectedAudienceOption?.value === 999999999999999
          ? 0
          : selectedAudienceOption?.value,
      type: notificationTypeValue,
      duration: messageTypeDurationValue,
    }

    if (isRecruitingDashboardNotification) {
      messagePayload = {
        ...messagePayload,
        user_ids: [selectedRecruitingUserId],
      }
    } else if (selectedRecipientOption && selectedRecipientOption.length > 0) {
      messagePayload = { ...messagePayload, user_ids: selectedRecipientOption }
    } else {
      messagePayload
    }

    if (socket !== null && messagePayload) {
      messagingService.emitMessagingSocket(
        'announcements',
        messagePayload,
        socket,
        updateOnSuccess,
        updateOnError
      )
    }
  }

  const getDismissDuration = (value: string) => {
    if (value) {
      if (value === '3s') {
        return 3000
      } else if (value === '5s') {
        return 5000
      } else if (value === '10s') {
        return 10000
      } else if (value === '20s') {
        return 20000
      } else if (value === 'Until closed manually') {
        return null
      }
    }
    return
  }

  const updateOnSuccess = () => {
    setTimeout(() => {
      setIsSubmissionSuccessful(true)
      onFormFieldsReset()
    }, 100)
  }

  const updateOnError = () => {
    setTimeout(() => {
      setIsSubmissionFailed(true)
    }, 100)
  }

  const onFormFieldsReset = () => {
    setNotificationTitleValue('')
    setTextAreaInputValue('')
    setSelectedAudienceOptionValue({ value: undefined })
    setSelectedNotificationTypeValue({ value: undefined })
    setSelectedRecipientOption(undefined)
    setSelectedRecipientValue(undefined)
    setSelectedMessageDismissDuration({
      value: 2,
      name: '5s',
    })
    setTextAreaInputCharacterCount(0)
  }

  // User Header Actions
  const OnCloseClick = () => {
    onFormFieldsReset()
    setMenuOpen(false)
    setEventNotificationPageOpen(false)
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
  }

  const OnGoBack = () => {
    if (isRecruitingDashboardNotification) {
      dispatch(updateHasNotificationPageOpen(false))
      dispatch(switchMenuPage(MenuPages.RecruitingDashboard))
    } else {
      if (isNotificationPublished) {
        setIsNotificationPublished(false)
        onFormFieldsReset()
      } else {
        setMenuOpen(false)
        setEventNotificationPageOpen(false)
        dispatch(switchMenuPage(MenuPages.Menu))
        dispatch(toggleMenu(true))
      }
    }
  }

  return (
    <div className={classes.paperContainer}>
      <IndexPageUpdater />
      <MenuDrawer
        isOpen={isMenuOpen}
        drawerHeader={
          <MenuHeader
            title={
              !isRecruitingDashboardNotification
                ? t('eventNotification.title')
                : t('recruiting.chatPage.notificationTitle')
            }
            onGoBack={OnGoBack}
            onClose={!isRecruitingDashboardNotification ? OnCloseClick : undefined}
          />
        }
      >
        {!isNotificationPublished && (!isSubmissionFailed || !isSubmissionSuccessful) && (
          <div className={classes.pageContainer}>
            <EventNotification
              titleInput={notificationTitle}
              testAreaInput={textAreaInputValue}
              isEventNotificationPageOpen={eventNotificationPageOpen}
              characterCount={textAreaInputCharacterCount}
              onTitleChange={onTitleValueChange}
              onTextAreaChange={onTextAreaValueChange}
            ></EventNotification>
            {!isRecruitingDashboardNotification && (
              <>
                <div className={classes.itemWrapper}>
                  <Dropdown
                    title={t('eventNotification.audienceOptions.title')}
                    itemsList={availableAudienceOptions}
                    selecteditem={selectedAudienceOption?.value || ''}
                    onOptionChange={onAudienceOptionValueChange}
                  ></Dropdown>
                </div>
                <div className={classes.itemWrapper}>
                  <DropdownFiltered
                    title={t('eventNotification.recipientOption.title')}
                    itemsList={availableRecipientOptions}
                    selecteditem={selectedRecipientValue || []}
                    onOptionChange={onRecipientOptionValueChange}
                  ></DropdownFiltered>
                </div>
              </>
            )}
            <div className={classes.itemWrapper}>
              <Dropdown
                title={t('eventNotification.notificationType.title')}
                itemsList={availableNotificationTypes}
                selecteditem={selectedNotificationType?.value || ''}
                onOptionChange={onNotificationTypeValueChange}
              ></Dropdown>
            </div>
            <div className={classes.itemWrapper}>
              <Dropdown
                title={t('eventNotification.messageDurationType.title')}
                itemsList={availableMessageDismissDurations}
                selecteditem={selectedMessageDismissDuration?.value}
                onOptionChange={onMessageDismissDurationValueChange}
              ></Dropdown>
            </div>
            <ShadowedButton
              text={t('eventNotification.submitButton')}
              buttonStyle={'default'}
              wrapperClasses={classes.submitButton}
              disabled={isEventNotificationFormEmpty()}
              onClick={onNotificationSubmit}
            ></ShadowedButton>
          </div>
        )}
        {isNotificationPublished && (isSubmissionFailed || isSubmissionSuccessful) && (
          <div className={`${classes.pageContainer} ${classes.notificationSubmitMessage}`}>
            <NotificationSubmitMessage
              isNotificationPublished={isNotificationPublished}
              isSubmissionSuccessful={isSubmissionSuccessful}
              isSubmissionFailed={isSubmissionFailed}
            ></NotificationSubmitMessage>
          </div>
        )}
      </MenuDrawer>
    </div>
  )
}

export default EventNotificationContainer
