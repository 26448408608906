import React, { FC } from 'react'
import { useAppSelector } from '../../Reducers/hooks'
import { RoomChatPages } from './RoomChatSlice'
import RoomChatMessagesContainer from './RoomChatMessagesContainer'
import RoomChatParticipantListContainer from './RoomChatParticipantListContainer'
import { IndexPageUpdater } from '../../Components/IndexPageUpdater/IndexPageUpdater'

export const RoomChatContainer: FC = () => {
  const activeRoomChatPage = useAppSelector((state) => state.roomChat.activeRoomChatpage)

  switch (activeRoomChatPage) {
    case RoomChatPages.RoomChatMessage:
      return (
        <>
          <IndexPageUpdater />
          <RoomChatMessagesContainer />
        </>
      )
    case RoomChatPages.RoomChatParticipantList:
      return (
        <>
          <IndexPageUpdater />
          <RoomChatParticipantListContainer />
        </>
      )
    default: {
      return null
    }
  }
}

export default RoomChatContainer
