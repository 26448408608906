/* eslint-disable no-unused-vars */
export enum FrontendTypes {
  VISITOR = 'visitor',
  BACKSTAGE = 'backstage',
  LIGHT = 'light',
  ADMIN = 'admin',
  SCRIPT = 'script',
  TRAVERSE = 'traverse',
}

export enum MessagingTopics {
  USER_EVENTS = 'user_events',
  USER_SCENE_EVENTS = 'user_scene_events',
  USER_ROOM_EVENTS = 'user_room_events',
  CHAT = 'chat',
  SESSIONS_TRIGGERS = 'sessions_triggers',
  USER_STATUS_CHANGES = 'user_status_changes',
  USER_ACCESS_CHANGE = 'user_access_change',
  ANNOUNCEMENTS = 'announcements',
  USER_PLAYER_EVENTS = 'user_player_events',
  USER_ACHIEVEMENT_EVENTS = 'user_achievement_events',
  VIEW_CONTENT = 'view_content',
}
