import Config from '../config.json'

type IndexPageData = {
  iconPath: string
}

const createHtmlIdString = (...args: string[]): string => {
  // Concatenates arbitrary number of strings, converting to lowercase and replacing spaces with hyphens
  let id = ''
  for (const str of args) {
    id += str.toLowerCase().replace(/ /g, '-')
  }

  return id
}

const getProjectName = (): string => {
  const starProject =
    Config.VSF_K8S_STAR_PROJECT && Config.VSF_K8S_STAR_PROJECT.toLowerCase() === 'true'
  const val = starProject ? 'STAR' : 'Virtual Show Floor'
  return val
}

const getIndexPageData = (): IndexPageData => {
  const linkIcon = document.querySelector('link[rel=icon]')
  const linkIconHrefValue = linkIcon?.getAttribute('href')
  let iconPath: string = linkIconHrefValue || '%PUBLIC_URL%/favicon.ico'

  if (getProjectName() === 'STAR') {
    iconPath = iconPath.replace('favicon.ico', 'faviconStar.ico')
  }

  return {
    iconPath,
  }
}

const projectName = getProjectName()

export { createHtmlIdString, projectName, getIndexPageData }
