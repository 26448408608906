import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import AuthLayout from '../../Containers/AuthenticationContainer/Auth/AuthLayout'
import TraverseOauthContainer from '../../Containers/AuthenticationContainer/TraverseOauthContainer/TraverseOauthContainer'
import { IndexPageUpdater } from '../../Components/IndexPageUpdater/IndexPageUpdater'

const useStyles = makeStyles(() =>
  createStyles({
    containerWrapper: {
      height: '100vh',
    },
  })
)

export const TraverseOAuthPage: FC = () => {
  const classes = useStyles()

  return (
    <>
      <IndexPageUpdater />
      <AuthLayout>
        <div className={classes.containerWrapper}>
          <TraverseOauthContainer />
        </div>
      </AuthLayout>
    </>
  )
}
export default TraverseOAuthPage
