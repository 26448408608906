import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import AuthLayout from '../../../Containers/AuthenticationContainer/Auth/AuthLayout'
import RegisterSuccessContainer from '../../../Containers/AuthenticationContainer/RegisterContainer/RegisterSuccessContainer'
import { IndexPageUpdater } from '../../../Components/IndexPageUpdater/IndexPageUpdater'

const useStyles = makeStyles(() =>
  createStyles({
    containerWrapper: {
      height: '100vh',
    },
  })
)

const AuthRegistrationSuccessPage: FC = () => {
  const classes = useStyles()

  return (
    <>
      <IndexPageUpdater />
      <AuthLayout>
        <div className={classes.containerWrapper}>
          <RegisterSuccessContainer />
        </div>
      </AuthLayout>
    </>
  )
}
export default AuthRegistrationSuccessPage
