import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { ShadowedButton } from '../../../Common/common-index'

interface Props {
  errorMessage?: string | undefined
  onClick: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& .MuiTextField-root': {
        boxSizing: 'border-box',
        justifyContent: 'center',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 3),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0),
          width: '400px',
        },
      },
    },
    headerWrapper: {
      fontFamily: 'Graphik',
      fontWeight: 'bold',
      textAlign: 'start',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    header: {
      lineHeight: '32px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    description: {
      fontSize: '16px',
      lineHeight: '24px',
      margin: theme.spacing(5, 2),
      [theme.breakpoints.up('md')]: {
        width: '400px',
        margin: theme.spacing(5, 0),
      },
    },
    button: {
      marginTop: theme.spacing(1),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
    },
    errorStatus: {
      color: theme.palette.error.main,
      margin: theme.spacing(2, 0),
      padding: theme.spacing(0, 3),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        maxWidth: '400px',
      },
    },
  })
)

const TraverseOauthLogin: FC<Props> = ({ errorMessage, onClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // On keyboard "Enter" key enter
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onClick()
    }
  }

  return (
    <form className={classes.root} onKeyDown={(e?: any) => onKeyPress(e)}>
      <div className={classes.headerWrapper}>
        <Typography className={classes.header} data-testid='title' variant={'h4'}>
          {t('authPages.TraverseOauthLogin.header')}
        </Typography>
        <Typography className={classes.description} data-testid='description' variant={'h6'}>
          {t('authPages.TraverseOauthLogin.description')}
        </Typography>
      </div>

      {errorMessage && errorMessage.length && (
        <Typography variant='subtitle1' className={classes.errorStatus}>
          {errorMessage}
        </Typography>
      )}

      <ShadowedButton
        text={t('authPages.TraverseOauthLogin.login')}
        buttonStyle={'default'}
        wrapperClasses={classes.button}
        onClick={onClick}
        id='traverseOAuth-login-submit'
      ></ShadowedButton>
    </form>
  )
}

export default TraverseOauthLogin
