import React, { FC, useEffect, useState } from 'react'
import { ToastProvider } from 'react-toast-notifications'

import RoomChatFeed from '../../Components/ToastNotifications/RoomChatFeed'
import EventNotificationFeed from '../../Components/ToastNotifications/EventNotificationFeed'
import { EventNotificationFeedContainer } from './EventNotificationFeedContainer'
import { RoomChatFeedContainer } from './RoomChatFeedContainer'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useAppSelector } from '../../Reducers/hooks'

const useStyles = makeStyles(() =>
  createStyles({
    buttonsMultiplayerRoom: {
      '& div[class*="react-toast-notifications__container"]': {
        top: '80px',
      },
    },
    // buttonsExpandView: {
    //   '& div[class*="react-toast-notifications__container"]': {
    //     top: '170px',
    //   },
    // },
  })
)

export const ToastNotificationContainer: FC = ({ children }) => {
  const classes = useStyles()
  const isMultiplayerRoom = useAppSelector((state) => state.room.isMultiplayerRoom)
  // const isExpandViewActive = useAppSelector((state) => state.toolButtons.isExpandViewActive)

  const [isMobile, setIsMobile] = useState(false)

  //Handle the screen size
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [isMobile])

  return (
    <>
      {/* Room chat notification news feed */}
      <ToastProvider
        components={{ Toast: RoomChatFeed }}
        autoDismiss={true}
        autoDismissTimeout={10000}
        newestOnTop={true}
        placement={isMobile ? 'top-left' : 'top-left'}
      >
        <RoomChatFeedContainer />
      </ToastProvider>

      {/* Event notification news feed */}
      <div
        id='notificationWrapper'
        className={isMultiplayerRoom ? `${classes.buttonsMultiplayerRoom}` : ''}
      >
        <ToastProvider
          components={{ Toast: EventNotificationFeed }}
          newestOnTop={true}
          placement={isMobile ? 'top-left' : 'top-left'}
          portalTargetSelector='#notificationWrapper'
        >
          <EventNotificationFeedContainer />
          {children}
        </ToastProvider>
      </div>
    </>
  )
}

export default ToastNotificationContainer
