import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ErrorContainer from '../../../Containers/AuthenticationContainer/Error/ErrorContainer'
import ErrorLayout from '../../../Containers/AuthenticationContainer/Error/ErrorLayout'
import { IndexPageUpdater } from '../../../Components/IndexPageUpdater/IndexPageUpdater'
interface errorProps {
  errorMessage?: string
  errorHint?: string
}
const useStyles = makeStyles(() =>
  createStyles({
    containerWrapper: {
      height: '100vh',
    },
  })
)

const Error: FC<errorProps> = ({ errorMessage, errorHint }) => {
  const classes = useStyles()

  return (
    <>
      <IndexPageUpdater />
      <ErrorLayout>
        <div className={classes.containerWrapper}>
          <ErrorContainer errorHint={errorHint} errorMessage={errorMessage} />
        </div>
      </ErrorLayout>
    </>
  )
}
export default Error
