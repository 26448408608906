import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'
import RoomContainer from '../../Containers/RoomContainer/RoomContainer'

import { IndexPageUpdater } from '../../Components/IndexPageUpdater/IndexPageUpdater'

const useStyles = makeStyles(() => ({
  roomContainer: {
    width: '100vw',
    height: '100vh',
    overflowY: 'hidden',
  },
}))

const RoomPage: FC = () => {
  const classes = useStyles()
  return (
    <>
      <IndexPageUpdater />
      <div className={classes.roomContainer}>
        <RoomContainer></RoomContainer>
      </div>
    </>
  )
}

export default RoomPage
