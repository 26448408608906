import React, { FC } from 'react'
import { useAppSelector } from '../../Reducers/hooks'
import { ChatPages } from './ChatSlice'
import ChatListContainer from './ChatListContainer'
import ChatMessagesContainer from './ChatMessagesContainer'
import ChatNewConversationContainer from './ChatNewConversationContainer'
import ChatParticipantListContainer from './ChatParticipantListContainer'
import { IndexPageUpdater } from '../../Components/IndexPageUpdater/IndexPageUpdater'

const ChatContainer: FC = () => {
  const activeChatPage = useAppSelector((state) => state.chat.activeChatPage)

  switch (activeChatPage) {
    case ChatPages.ChatList:
      return (
        <>
          <IndexPageUpdater />
          <ChatListContainer />
        </>
      )
    case ChatPages.ChatMessage:
      return (
        <>
          <IndexPageUpdater />
          <ChatMessagesContainer />
        </>
      )
    case ChatPages.ChatNewConversation:
      return (
        <>
          <IndexPageUpdater />
          <ChatNewConversationContainer />
        </>
      )
    case ChatPages.ChatParticipantList:
      return (
        <>
          <IndexPageUpdater />
          <ChatParticipantListContainer />
        </>
      )
    default: {
      return null
    }
  }
}

export default ChatContainer
