import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Link from '@material-ui/core/Link'
import { ShadowedButton } from '../../../Common/common-index'
import {
  authService,
  FrontendTypes,
  localStorageService,
  ProviderResponse,
  // Provider,
} from '../../../Services/services-index'
import FormTextField from '../FormTextField/FormTextField'
import { Button, Box, Checkbox, createStyles } from '@material-ui/core'

interface Props {
  onSubmit: Function
  loginFailMsg: string | undefined
  eventCodeFromQuery: string
  accessCodeFromQuery: string
  providers?: ProviderResponse
}

const useStyles = (providers?: any) =>
  makeStyles((theme: Theme) => {
    const providersButtons = {}
    providers.map((provider: any) => {
      providersButtons[`button-${provider.name}`] = {
        ...provider.button_attributes,
        ...provider.hover_attributes,
      }
    })
    return createStyles({
      root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1, 0),
          padding: theme.spacing(0, 3),
          boxSizing: 'border-box',
          width: '100%',
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0),
            width: '400px',
          },
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      headerWrapper: {
        fontFamily: 'Graphik',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(5, 0),
        [theme.breakpoints.up('md')]: {
          width: '400px',
        },
      },
      oceanRaceCaption: {
        fontFamily: 'Graphik-Regular',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '17px',
      },
      header: {
        lineHeight: '32px',
        [theme.breakpoints.up('sm')]: {
          width: '100%',
        },
      },
      alignCenter: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      youCan: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(-2.5),
      },
      buttonWrapper: {
        borderRadius: 0,
        marginTop: theme.spacing(5),
        position: 'relative',
        width: '100%',
        [theme.breakpoints.up('md')]: {
          maxWidth: '400px',
        },
      },
      loginButton: {
        marginTop: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          maxWidth: '400px',
        },
      },
      button: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0,
        boxShadow: 'none',
        height: '46px',
        textTransform: 'none',
        transition: 'all 0.4s ease-in-out',
        width: '100%',
        zIndex: 1,
      },
      ...providersButtons,
      errorStatus: {
        fontSize: '14px',
        color: theme.palette.error.main,
        margin: theme.spacing(2, 0),
        padding: theme.spacing(0, 3),
        [theme.breakpoints.up('sm')]: {
          marginLeft: 0,
          maxWidth: '400px',
        },
      },
      hiddenField: { display: 'none' },
      container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      },
      contentItem: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
      },
      personalDataItem: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
      },
      smallCaption: {
        fontFamily: 'Graphik-Regular',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '17px',
      },
      privacyError: {
        color: 'red',
      },
      buttonImage: {
        width: '23px',
        filter: 'brightness(78%)',
      },
    })
  })

const LoginForm: FC<Props> = ({
  onSubmit,
  loginFailMsg,
  eventCodeFromQuery,
  accessCodeFromQuery,
  providers,
}) => {
  const classes = useStyles(providers?.providers)()
  const { t } = useTranslation()

  // Initialization of State value
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [eventName, setEventName] = useState<string>(eventCodeFromQuery)
  const [usernameTouched, setUsernameTouched] = useState<boolean>(false)
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false)
  const [eventNameTouched, setEventNameTouched] = useState<boolean>(false)
  const [usernameFieldHasError, setUsernameFieldHasError] = useState<boolean>(false)
  const [passwordFieldHasError, setPasswordFieldHasError] = useState<boolean>(false)
  const [eventCodeFieldHasError, setEventCodeFieldHasError] = useState<boolean>(false)
  // Providers Login access
  const hasDefaultLogIn = providers && providers.user_pass_login === 'yes'

  // Constants initialization
  const OAuth2URL = authService.servicePrefix + '/oauth2'
  const privacyURL = 'https://www.accenture.com/us-en/about/privacy-policy'
  const [privacySetting, setPrivacySetting] = useState<boolean>(false)
  const [isEnter, setIsEnter] = useState<boolean>(false)
  const label = { 'aria-label': 'Checkbox' }
  const [localStorageSetting, setLocalStorageSetting] = useState<boolean>(false)

  const handleLocalStorage = () => {
    setLocalStorageSetting(!localStorageSetting)
  }

  const cookieLinkClick =
    'https://www.accenture.com/us-en/support/company-cookies-similar-technology'

  const handleChange = () => {
    setPrivacySetting(!privacySetting)
  }
  useEffect(() => {
    if (eventCodeFromQuery) {
      localStorageService.setLocalStorageItemValue('eventCode', JSON.stringify(eventName))
    }
    if (accessCodeFromQuery) {
      localStorageService.setLocalStorageItemValue(
        'accessCode',
        JSON.stringify(accessCodeFromQuery)
      )
    }
  }, [])

  useEffect(() => {
    formValidation()
  }, [])

  const setEventLocalStorage = () => {
    if (!eventCodeFromQuery) {
      localStorageService.setLocalStorageItemValue('eventCode', JSON.stringify(eventName))
    }
  }

  useEffect(() => {}, [username, password, eventName])

  // On Submit button click
  const onClick = (e: any) => {
    e.preventDefault()
    onSubmit(username, password, eventName)
  }

  // On keyboard "Enter" key enter
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && localStorageSetting && privacySetting) {
      setIsEnter(false)
      onClick(e)
    } else if (e.key === 'Enter' && (!localStorageSetting || !privacySetting)) {
      setIsEnter(true)
    }
  }

  // Form validations
  const formValidation = () => {
    usernameTouched && !username ? setUsernameFieldHasError(true) : setUsernameFieldHasError(false)
    passwordTouched && !password ? setPasswordFieldHasError(true) : setPasswordFieldHasError(false)
    eventNameTouched && !eventName
      ? setEventCodeFieldHasError(true)
      : setEventCodeFieldHasError(false)
  }

  // On Email field  value change
  const onEmailFieldValueChange = (value: string) => {
    setUsernameTouched(true)
    setUsername(value)
  }

  // On Password field  value change
  const onPasswordFieldValueChange = (value: string) => {
    setPasswordTouched(true)
    setPassword(value)
  }

  // On EventCode field  value change
  const onEventNameFieldValueChange = (value: string) => {
    setEventNameTouched(true)
    setEventName(value)
  }

  // On SSO login button click
  const onSSOLogInClick = (SSOType: string) => {
    const traverseRedirect = localStorageService.getLocalStorageItemValue('traverseRedirect')
    if (traverseRedirect) {
      return OAuth2URL + '?frontend=' + FrontendTypes.TRAVERSE + '&provider=' + SSOType
    } else {
      return OAuth2URL + '?frontend=' + FrontendTypes.VISITOR + '&provider=' + SSOType
    }
  }

  return (
    <form className={classes.root} onSubmit={onClick} onKeyDown={(e?: any) => onKeyPress(e)}>
      <div className={classes.headerWrapper}>
        {eventName === 'theoceanrace2023' ? (
          <>
            <Typography className={classes.header} data-testid='title' variant={'h4'}>
              {'Welcome to The Ocean Race Metaverse!'}
            </Typography>
            <p className={classes.oceanRaceCaption}>
              {'To access our metaverse for the first time, you’ll need to register for a code '}
              <Link
                href={'https://theoceanrace.qualifioapp.com/quiz/1210611_2826/.html'}
                target='_blank'
                id='disclaimer--link'
              >
                {'here'}
              </Link>
            </p>
            <p className={classes.oceanRaceCaption}>
              {
                'If you already have your access code or have logged in before, please click one of the below:'
              }
            </p>
          </>
        ) : (
          <Typography className={classes.header} data-testid='title' variant={'h4'}>
            {t('authPages.logInContainer.title')}
          </Typography>
        )}
      </div>
      {hasDefaultLogIn && (
        <FormTextField
          id='login-form-email'
          value={username}
          isPasswordField={false}
          autoComplete={'username'}
          error={usernameFieldHasError}
          title={t('authPages.logInForm.email.header')}
          placeholder={t('authPages.logInForm.email.placeholder')}
          onChange={(e) => onEmailFieldValueChange(e)}
          inputProps={{
            maxLength: 2000,
          }}
          errorMessage={t('authPages.logInForm.email.errorMessage')}
        />
      )}
      {hasDefaultLogIn && (
        <FormTextField
          id='login-form-password'
          title={t('authPages.logInForm.password.header')}
          placeholder={t('authPages.logInForm.password.placeholder')}
          value={password}
          onChange={(e) => onPasswordFieldValueChange(e)}
          isPasswordField={true}
          autoComplete={'current-password'}
          error={passwordFieldHasError}
          inputProps={{
            maxLength: 2000,
          }}
          errorMessage={t('authPages.logInForm.password.errorMessage')}
        />
      )}
      {!eventCodeFromQuery ? (
        <FormTextField
          type='hidden'
          id='login-form-event-code'
          className={eventCodeFromQuery ? classes.hiddenField : ''}
          title={t('authPages.logInForm.eventCode.header')}
          placeholder={t('authPages.logInForm.eventCode.placeholder')}
          value={eventName}
          autoComplete={'off'}
          onChange={(e) => onEventNameFieldValueChange(e)}
          isPasswordField={false}
          error={eventCodeFieldHasError}
          inputProps={{
            maxLength: 2000,
          }}
          errorMessage={t('authPages.logInForm.eventCode.errorMessage')}
        />
      ) : (
        <Typography></Typography>
      )}
      {hasDefaultLogIn && (
        <>
          {loginFailMsg && (
            <Typography variant='subtitle2' className={classes.errorStatus}>
              {loginFailMsg}
            </Typography>
          )}
          <ShadowedButton
            id='login-form-submit'
            text={t('authPages.logInForm.button.login')}
            buttonStyle={'default'}
            wrapperClasses={classes.loginButton}
            disabled={!(username && password && eventName && privacySetting && localStorageSetting)}
            onClick={onClick}
          ></ShadowedButton>
        </>
      )}
      {hasDefaultLogIn && providers && providers?.providers.length > 0 && (
        <Typography variant='body1' className={classes.youCan}>
          {t('authPages.authFooter.youCan')}
        </Typography>
      )}
      {providers &&
        providers.providers.map((provider) => {
          return (
            <div className={`${classes.alignCenter} ${classes.buttonWrapper}`}>
              <Button
                id={provider.button_description}
                variant='contained'
                className={`${classes.button} ${classes[`button-${provider.name}`]}`}
                disabled={!(privacySetting && localStorageSetting)}
                onClick={() => setEventLocalStorage()}
                href={onSSOLogInClick(provider.name)}
                startIcon={<img src={provider.button_image} className={`${classes.buttonImage}`} />}
              >
                <Typography variant='h5'>{provider.button_text}</Typography>
              </Button>
            </div>
          )
        })}

      <div className={classes.container}>
        <Box className={`${classes.contentItem}`}>
          <Typography
            className={`${classes.smallCaption} ${
              !privacySetting && !localStorageSetting && isEnter && classes.privacyError
            }`}
          >
            <Box>
              <Checkbox
                value={privacySetting}
                onChange={handleChange}
                inputProps={label}
                id='privacy-policy-link-checkbox'
                color='default'
              />
              <span className={`${!privacySetting && isEnter && classes.privacyError}`}>
                {t('authPages.consentRequirements.message')}
              </span>
              <Link
                target='_blank'
                className={`${!privacySetting && isEnter && classes.privacyError}`}
                underline='always'
                href={privacyURL}
                key={privacyURL}
                id='privacy-policy-link'
              >
                {t('authPages.consentRequirements.privacyPolicy')}
              </Link>
              {t('authPages.consentRequirements.message2')}
            </Box>
            <Box>
              <Checkbox
                value={localStorageSetting}
                onChange={handleLocalStorage}
                inputProps={label}
                id='local-storage-login-checkbox'
                color='default'
              />
              <span className={`${!localStorageSetting && isEnter && classes.privacyError}`}>
                {t('authPages.localStorageConsent.localStorageMessage')}
              </span>
            </Box>
          </Typography>
        </Box>
        <Box className={`${classes.personalDataItem}`}>
          <Typography className={`${classes.smallCaption}`}>
            <div>{t('authPages.consentRequirements.personalData')}</div>
            <div>{t('authPages.consentRequirements.personalData2')}</div>
            <div>
              {t('authPages.consentRequirements.personalData3')}
              <Link
                target='_blank'
                underline='always'
                href={privacyURL}
                key={privacyURL}
                id='privacy-policy-link'
              >
                {t('authPages.consentRequirements.privacyStatement')}
              </Link>
              {t('authPages.consentRequirements.importantInformation')}
            </div>
          </Typography>
        </Box>
        <Box className={`${classes.contentItem}`}>
          <div>
            <Typography className={`${classes.smallCaption}`}>
              {t('authPages.cookieConsent.cookieMessage')}
            </Typography>
            <Link
              target='_blank'
              className={`${classes.smallCaption}`}
              underline='always'
              href={cookieLinkClick}
              key={cookieLinkClick}
              id='cookie-policy-link'
            >
              {t('authPages.cookieConsent.cookiePolicy')}
            </Link>
          </div>
        </Box>
      </div>
      <Box className={`${classes.contentItem}`}>
        <div>
          <Link
            target='_blank'
            className={`${classes.smallCaption}`}
            underline='always'
            href={cookieLinkClick}
            key={cookieLinkClick}
            id='cookie-policy-link'
          />
        </div>
      </Box>
    </form>
  )
}

export default LoginForm
