import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { ShadowedButton } from '../../../Common/common-index'
import { Link, useHistory } from 'react-router-dom'

interface errorProps {
  errorMessage?: string
  errorHint?: string
  hasEventBaseDeskEmailId?: boolean
  eventBaseHelpDeskEmailId?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& .MuiTextField-root': {
        boxSizing: 'border-box',
        justifyContent: 'center',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 3),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0),
          width: '400px',
        },
      },
    },
    fieldItem: {
      alignItems: 'flex-start',
      padding: theme.spacing(2, 0),
      display: 'inline-grid',
      flexDirection: 'column',
      marginBottom: '24px',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    headerWrapper: {
      fontFamily: 'Graphik',
      fontWeight: 'bold',
      textAlign: 'start',
      width: '100%',
      padding: theme.spacing(5, 0),
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    header: {
      lineHeight: '32px',
      padding: theme.spacing(2, 0),
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    button: {
      marginTop: theme.spacing(2),
      width: '100%',
      alignItems: 'flex-start',
      [theme.breakpoints.up('md')]: {
        maxWidth: '220px',
      },
    },
    input: {
      fontSize: '14px',
      padding: theme.spacing(2, 0),
      display: 'flex',
    },
    error: {
      fontSize: '14px',
      fontFamily: 'Graphik-Regular bold',
      padding: theme.spacing(2, 0),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    link: {
      color: theme.palette.primary[400],
      cursor: 'pointer',
    },
    emailId: {
      color: theme.palette.primary.main,
    },
    smallCaption: {
      fontFamily: 'Graphik-Regular bold',
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: '17px',
    },
  })
)

const ErrorForm: FC<errorProps> = ({
  errorMessage,
  errorHint,
  eventBaseHelpDeskEmailId,
  hasEventBaseDeskEmailId,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  let errorHintValue = errorHint
  let errorMessageValue = errorMessage

  if (!errorHintValue) {
    errorHintValue = t('authPages.error.pageNotFoundLabel')
    errorMessageValue = t('authPages.error.pageNotFoundMessage')
  }
  const errorHintMarkdown: string = errorHintValue ?? ''
  const defaultHelpDeskId = 'STAR.helpdesk@accenture.com'
  // On VSF Support email click
  const onSupportEmailIdClick = (emailId: any) => {
    const encodedSupportEmailId = encodeURI(emailId)
    const emailData = `mailto:${encodedSupportEmailId}`
    window.open(emailData, '_self', 'noopener,noreferrer')
  }

  return (
    <form className={classes.root}>
      <div className={classes.headerWrapper}>
        <div className={classes.fieldItem}>
          <Typography className={classes.header} variant={'h4'}>
            {t('authPages.error.title')}
          </Typography>
          <Typography className={classes.input} variant={'h3'}>
            {errorMessageValue}
          </Typography>
          <Typography className={classes.error} variant={'h4'}>
            {t('authPages.error.helpdeskInformation')}
          </Typography>
          <Typography className={classes.error} variant={'h4'}>
            <div dangerouslySetInnerHTML={{ __html: errorHintMarkdown }}></div>
          </Typography>
          <Link
            className={`${classes.smallCaption} ${classes.link} ${classes.emailId}`}
            onClick={() =>
              onSupportEmailIdClick(
                hasEventBaseDeskEmailId ? eventBaseHelpDeskEmailId : defaultHelpDeskId
              )
            }
            id='email-support-link'
            to={''}
          >
            {hasEventBaseDeskEmailId ? eventBaseHelpDeskEmailId : defaultHelpDeskId}
          </Link>
        </div>

        <ShadowedButton
          text={t('authPages.error.closeButton')}
          buttonStyle={'default'}
          wrapperClasses={classes.button}
          onClick={() => history.push('/lobby')}
        ></ShadowedButton>
      </div>
    </form>
  )
}
export default ErrorForm
