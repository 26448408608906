import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AuthLayout from '../../Containers/AuthenticationContainer/Auth/AuthLayout'
import EventInfoContainer from '../../Containers/EventInfo/EventInfoContainer'
import { IndexPageUpdater } from '../../Components/IndexPageUpdater/IndexPageUpdater'

const useStyles = makeStyles(() =>
  createStyles({
    containerWrapper: {
      marginTop: '24px !important',
      height: '100vh',
    },
  })
)

const EventInfo: FC = () => {
  const classes = useStyles()

  return (
    <>
      <IndexPageUpdater />
      <AuthLayout>
        <div className={classes.containerWrapper}>
          <EventInfoContainer />
        </div>
      </AuthLayout>
    </>
  )
}
export default EventInfo
